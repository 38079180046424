#header-nav {
  display: flex;
}

#header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
}

#header-disclaimer {
  font-size: 12px;
  width: 85%;
}

@media (max-width: 768px) {
  #header {
    flex-direction: column;
  }
  #header-disclaimer {
    margin-top: 20px;
  }
  #header-logo {
    width: 15%;
  }
}
