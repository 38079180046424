@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.3/font/bootstrap-icons.css");

.exercise-info {
  display: flex;
}
.exercise-info-name {
  color: #2e588d;
  text-align: left;
  margin-bottom: 0px;
}

.btn-primary {
  background-color: white;
  border: none;
  width: 100%;
  padding-bottom: 0px;
  border-radius: 0px;
}

.btn-primary:hover {
  background-color: #2e588d;
}
.btn-primary:hover h5,
.btn-primary:hover p {
  color: white;
}
.exercise-info-muscle {
  font-size: 15px;
  text-align: left;
  color: #2e588d;
  padding-top: 0px;
}

.videos {
  display: flex;
  justify-content: space-between;
}
.bi {
  color: #2e588d;
  font-size: 150%;
  margin-right: 10px;
}

.exercise-steps {
  display: flex;
}

.steps {
  margin-top: 30px;
}

#muscle-text {
  margin-bottom: 0px;
}
#category-text {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .exercise-info-name {
    font-size: 15px;
  }
  .exercise-info-muscle {
    font-size: 10px;
  }
}
