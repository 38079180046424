.card {
  display: inline-block;
  white-space: wrap;
  animation: slide-up 0.7s ease;
  margin-left: 20px;
  width: 18rem;
}

.card-body {
  text-align: left;
  border-radius: 5px;
}

#exerciseName {
  text-align: left;
}

.card:hover span {
  color: white;
}

.steps {
  white-space: wrap;
}

@keyframes slide-up {
  0% {
    opacity: 0;
  }
  100% {
    opcaity: 1;
  }
}

@media (max-width: 768px) {
  .card-title {
    font-size: 15px;
  }
  .card-subtitle {
    font-size: 11px;
  }
  .card {
    width: 12rem;
  }
}
