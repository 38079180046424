.exercise {
  display: inline-block;
  width: 80%;
  margin-left: 50px;
}

#exercise-search {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
#exercise-search-results {
  border: 1px solid #dee2e6;
  border-top: none;
  overflow-y: auto;
}

@media (max-width: 768px) {
  .form-label {
    font-size: 11px;
  }
}
