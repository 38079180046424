main {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.diagram {
  width: 45%;
  height: 45%;
}
path {
  transition: fill 0.3 ease-in-out;
}

@media (max-width: 768px) {
  main {
    flex-direction: column;
    margin: 0px;
  }
  .search {
    order: -1;
  }
  .diagram {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
